import React, {FC} from "react";
import {Layout} from "../../components/Layout";
import { networkSupportService } from "../../dataSet/Services";
import ActionWidget from "../../components/ActionWidget";
import technician from "../../assets/images/technician.svg";
import idea from "../../assets/images/idea.svg";
import monitor from "../../assets/images/dashboard.svg";
import customer from "../../assets/images/customer-representative.svg";
import Team from '../../assets/images/developer-team.svg'
import * as animationData from "../../assets/lottie/network.json";
import Lottie from "react-lottie";

const steps = [
    {title: 'Experienced Technicians', description: 'Our certified technicians have extensive experience in network support and infrastructure management.', imgSrc: technician},
    {title: 'Proactive Approach', description: 'We proactively monitor and maintain your network to prevent issues before they arise.', imgSrc: monitor},
    {title: 'Cost-Effective Solutions', description: 'Our services are designed to provide maximum value, ensuring your network runs efficiently without breaking the bank.', imgSrc: idea},
    {title: 'Customer-Centric Service', description: 'We prioritize your satisfaction, offering personalized support and dedicated account managers.', imgSrc: customer},
];

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const NetworkSupport: FC = () => {
    return (
        <Layout>
            <section className={'widgetBanTxtBtnImg'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <div className={'page-banner'}>
                                <div className={'content'}>
                                    <h1 className="banner-title">
                                        Reliable Network Support Services for Your Business</h1>
                                    <p className={'subtitle'}>Ensuring Seamless Connectivity and Maximum Efficiency</p>
                                    <a href="/contact-us" target="_self" className="btn-solid bg-white">Get in touch</a>
                                </div>
                                <div className={'icon'}>
                                    <Lottie options={defaultOptions} height={300} width={400}/>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <div className={'separator'}/>
            <section className={'widgetColServiceCard'}>
                <div className={'container'}>
                    <h2 className={'sectionTitle text-center'}>Network Support Services</h2>
                    <p className={'sectionDescription text-center'}>From Device Replacement to Infrastructure Upgrades,
                        We’ve Got You Covered</p>
                    <div className={'row'}>
                        {networkSupportService.map(service => (
                                    <div className={'col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'} >
                                        <div className={'card tech-card'} style={{padding: 10, alignItems: 'center', height: 300}}>
                                                <img src={service.icon} height={100} width={100} alt={'icon'}/>
                                            <div style={{marginTop: 10}}>
                                                <h5 className={'text-center'}>{service.title}</h5>
                                                <p className={'text-center'}>{service.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                        }
                    </div>
                </div>
            </section>
            <section className={'widgetColTxtImgBul'} style={{backgroundColor: '#F9F9FE'}}>
                <div className={'container'}>
                    <h2 className={'sectionTitle text-center'}>Why Choose us?</h2>
                    <p className={'sectionDescription text-center'}>Expert Network Support with a Focus on Your Success</p>
                    <div className={'row mt-5 justify-content-between'}>
                        <div className={'col-lg-4 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2'}>
                            <img src={Team} alt={'icon'} />
                        </div>
                        <div className={'col-lg-8 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1'}>
                            <div className={'horizontalPoints'}>
                                {steps.map((item) => (
                                    <div className={'points'}>
                                        <div className={'image'}>
                                            <div className={'imageWrap'}>
                                                <img src={item.imgSrc} height={24} width={24} alt={'icon'}/>
                                            </div>
                                        </div>
                                        <div className={'text'} style={{marginTop: 10}}>
                                            <h4>{item.title}</h4>
                                            <p>{item.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ActionWidget
                text={'Ready to Enhance Your Network? Contact us today for a consultation and discover how we can transform your workplace.'}
                sectionStyle={{marginTop: 30}}
            />
        </Layout>
    )
}
