import React, { useState, useEffect } from 'react';
import './Stepper.css';
import MUIStepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AddTaskIcon from '@mui/icons-material/AddTask';
import SettingsIcon from '@mui/icons-material/Settings';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { styled } from '@mui/material/styles';
import { StepContent, Typography } from "@mui/material";

const Stepper = ({ steps, interval = 3000, isMobileView = false }: {steps: any[], interval: number, isMobileView: boolean}) => {
    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentStep(prevStep => (prevStep + 1) % steps.length);
        }, interval);

        return () => clearInterval(timer);
    }, [steps.length, interval]);

    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 22,
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 3,
            border: 0,
            backgroundColor:
                theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderRadius: 1,
        },
    }));

    const ColorlibStepIconRoot = styled('div')<{
        ownerState: { completed?: boolean; active?: boolean };
    }>(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
            backgroundImage:
                'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
            backgroundImage:
                'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        }),
    }));

    const ColorlibStepIcon = (stepProps: StepIconProps) => {
        const { active, completed, className } = stepProps;

        const icons: { [index: string]: React.ReactElement } = {
            1: <AssessmentIcon />,
            2: <AddTaskIcon />,
            3: <SettingsIcon />,
            4: <SupportAgentIcon />,
        };

        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                {icons[String(stepProps.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    return (
        <div className="stepwrap">
            {isMobileView ? (
                <MUIStepper activeStep={currentStep} orientation="vertical">
                    {steps.map((step, index) => (
                        <Step key={step.title}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>
                                {step.title}
                            </StepLabel>
                            <StepContent style={{height: 'max-content'}}>
                                <Typography>{step.description}</Typography>
                            </StepContent>
                        </Step>
                    ))}
                </MUIStepper>
            ) :(<MUIStepper alternativeLabel activeStep={currentStep} connector={<ColorlibConnector/>}>
                {steps.map((step, index) => (
                    <Step key={index}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>{step.title}</StepLabel>
                        <StepContent sx={{borderLeft: 0, marginTop: 2}}>{step.description}</StepContent>
                    </Step>
                ))}
            </MUIStepper>)}
        </div>
    );
};

export default Stepper;
