import {FC} from "react";
import {Layout} from "../../components/Layout";
import Security from "../../assets/images/rendering-smart-home-device.jpg";
import { securityCameraService } from "../../dataSet/Services";
import Consultation from "../../assets/images/consultation.svg";
import Assessment from "../../assets/images/assessment.svg";
import Installation from "../../assets/images/install.svg";
import Configuration from "../../assets/images/configuration.svg";
import Support from "../../assets/images/24-hours-service.svg";
import ActionWidget from "../../components/ActionWidget";
import Lottie from 'react-lottie';
import * as animationData from '../../assets/lottie/camera.json'
import * as cameraData from '../../assets/lottie/camera1.json'
import {HeroBannerWidget} from "../../components/HeroBanerWidget";


const steps = [
    {title: '1. Consultation', description: 'We start with a thorough consultation to understand your security needs.', imgSrc: Consultation},
    {title: '2. Site Assessment', description: 'Our team conducts a detailed site assessment to determine optimal camera placement.', imgSrc: Assessment},
    {title: '3. Installation', description: 'We professionally install your security cameras, ensuring minimal disruption.', imgSrc: Installation},
    {title: '4. Setup and Configuration', description: 'We configure the system and provide training on how to use it effectively.', imgSrc: Configuration},
    {title: '5. Ongoing Support', description: 'Our team is always available for maintenance, upgrades, and support.', imgSrc: Support},
];

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: cameraData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};


export const SecurityCameras: FC = () => {
    return (
        <Layout>
            <HeroBannerWidget heroUrl={Security} />
            <div className={'separator'}/>
            <section className={'widgetColTxtImgBul'}>
                <div className={'container'}>
                    <h2 className={'sectionTitle text-center'}>Comprehensive Security Camera Setup Services</h2>
                    <p className={'sectionDescription text-center'}><span className={'sectionSubTitle'}>Why choose us? </span>Ensuring Safety and Security with Expert Installation and Cutting-Edge Technology</p>
                    <div className={'container'}>
                        <div className={'row justify-content-around'}>
                            <div className={'col-lg-4 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2'}>
                                <Lottie options={defaultOptions}/>
                            </div>
                            <div className={'col-lg-8 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1'}>
                                <div className={'horizontalPoints'}>
                                    {securityCameraService.map((benfit, index) => (
                                        <div className={'points'}>
                                            <div className={'icon'}>
                                            <span className={'iconwrap'}>
                                            <img src={benfit.icon} height={16} width={16} alt="icon"/>
                                            </span>
                                            </div>
                                            <div className={'text'}>
                                                <p className={'desc'}>{benfit.title}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={'widgetColrTxt'}>
                <div className={'container'}>
                    <div className={'bgblack'}>
                        <div className={'row align-items-center justify-content-center'} style={{padding: 24}}>
                            <div className={'col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12'}>
                                <h2 className={'title text-xl-start text-lg-center text-md-center text-sm-center text-center'}>We
                                    specialize in providing top-notch security camera setup
                                    services to safeguard your property. Our team of experienced professionals uses the
                                    latest technology to ensure comprehensive surveillance and peace of mind.</h2>
                            </div>
                            <div className={'col-lg-4 d-lg-flex d-md-none d-sm-none d-none expertImg justify-content-center'}>
                                <Lottie options={defaultOptions1} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={'widgetColStepCard'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <h2 className="sectionTitle text-center">
                            Our Setup process follows a <span className="text-lightblue">proven, step-by-step approach that guarantees success</span>
                        </h2>
                        <p className="sectionDescription text-center">
                            We offer comprehensive security camera setup services customized to suit the unique
                            requirements of businesses across various industries.</p>
                    </div>
                    <div className="row d-lg-flex d-md-flex d-sm-none d-none">
                        {steps.map((step) => (
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className={'imgTitleTextCard'}>
                                    <div className={'heading'}>
                                        <div className={'icon'}>
                                            <img src={step.imgSrc} alt={'icon'}/>
                                        </div>
                                        <h4>{step.title}</h4>
                                    </div>
                                    <p className={'card-description'}>{step.description}</p>
                                </div>

                            </div>
                        ))}
                    </div>
                    <div className="row d-lg-none d-md-none d-sm-flex d-flex">
                        {steps.map((step) => (
                            <div className="col-12">
                                <div className={'imgTitleTextCard'}>
                                    <div className={'heading'}>
                                        <div className={'icon'}>
                                            <img src={step.imgSrc} alt={'icon'}/>
                                        </div>
                                        <h4>{step.title}</h4>
                                    </div>
                                    <p className={'card-description'}>{step.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <ActionWidget
                text={'Ready to enhance your security? Contact us today for a free consultation and quote.'}
                sectionStyle={{marginTop: 30}}
            />
        </Layout>
    )
}
