import {ReactNode} from "react";
import {Header} from "../Header";
import {Footer} from "../Footer";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export const Layout = ({ children }: { children: ReactNode }) => {
    return (
        <main>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
                <Header />
                {children}
                <Footer />

        </main>
    )
}
