import CyberSecurity from "../assets/images/cyber-4610993_1920.jpg";
import CloudMigration from "../assets/images/cloud-8598424_1920.jpg";
import Microsoft from "../assets/images/engineer-8499904_1920.jpg";
import NetworkSecurity from "../assets/images/network-2402637_1920.jpg";
import Technician from "../assets/images/woman-8499928_1920.jpg";
import Camera from "../assets/images/cctv-7267534_1920.jpg";
import Analytics from "../assets/images/analytics.png";
import monitoring from "../assets/images/analyze-on-screen.svg";
import digital from "../assets/images/digital-nomad.svg";
import performance from "../assets/images/employee-performance.svg";
import secure from "../assets/images/solve-digital-security.svg";
import dataProcessing from "../assets/images/cloud-data-processing.svg";
import dataTransfer from "../assets/images/cloud-data-transfer.svg";
import webSecurity from "../assets/images/web-security.svg";
import cloudBackup from "../assets/images/cloud-backup.svg";
import deskSupport from "../assets/images/deskSupport.jpg";
import networkSupport from "../assets/images/network-support.jpg";
import dataBackup from "../assets/images/dataBackup.jpg";
import remoteSupport from "../assets/images/remoteSupport.jpg";
import malwareSupport from "../assets/images/hacker-activity-concept.png";
import training from "../assets/images/training.jpg";
import troubleshooting from "../assets/images/troubleshooting.jpg";
import configuration from "../assets/images/configuration.jpg";
import eye from "../assets/images/button-153681.svg";


// <a href="https://www.freepik.com/free-ai-image/3d-cartoon-scene-depicting-variety-people-multitasking_152373482.htm#fromView=search&page=1&position=0&uuid=da43389f-b154-47e3-848b-1ca8fc802b3d">Image by freepik</a>

export const servicesGroup = [
    {
        title: 'CyberSecurity',
        imgSrc: CyberSecurity,
        description: 'We protect your business with comprehensive solutions tailored to safeguard your data, networks, and operations.',
        url: '/services/cybersecurity',
    },
    {
        title: 'Cloud Migration',
        imgSrc: CloudMigration,
        description: 'We specialize in seamless cloud migration services, helping businesses transition securely to scalable and efficient cloud environments.',
        url: '/services/cloud-migration',
    },
    {
        title: 'Microsoft 365',
        imgSrc: Microsoft,
        description: 'Setup and Migration services, ensuring a smooth transition to cloud-based productivity tools, optimize collaboration and efficiency',
        url: '/services/microsoft-service',
    },
    {
        title: 'Network Support',
        imgSrc: NetworkSecurity,
        description: 'Ensures robust defenses, safeguarding your data and operations with cutting-edge technologies and proactive monitoring.',
        url: '/services/network-security',
    },
    {
        title: 'Technical Support',
        imgSrc: Technician,
        description: 'We specialize in seamless cloud migration services, helping businesses transition securely to scalable and efficient cloud environments.',
        url: '/services/technical-support',
    },
    {
        title: 'Security Cameras',
        imgSrc: Camera,
        description: 'Setup and Migration services, ensuring a smooth transition to cloud-based productivity tools, optimize collaboration and efficiency.',
        url: '/services/security-cameras',
    },
    {
        title: 'IT Service for Non-Profits',
        imgSrc: Analytics,
        description: 'Ensures robust defenses, safeguarding your data and operations with cutting-edge technologies and proactive monitoring.',
        url: '/services/non-profits',
    }
]

export const cloudMigrationData = [
    {'title': 'We optimize your cloud migration to reduce upfront costs with a pay-as-you-go model and offer a range of customizable services (IaaS, PaaS, SaaS) tailored to your specific business needs.'},
    {'title': 'Our cloud migration ensures your resources scale effortlessly to meet changing demands, leveraging high-performance computing for reliable and consistent application performance.'},
    {'title': 'We facilitate access to your applications and data from anywhere, enhancing real-time collaboration and data sharing among remote teams post-migration.'},
    {'title': 'Our migration process includes advanced security features, such as encryption and multi-factor authentication, ensuring your data meets industry standards and regulatory compliance.'},
    {'title': 'We provide robust disaster recovery solutions to ensure business continuity and handle automatic updates to reduce maintenance burdens, keeping your systems secure and up-to-date post-migration'},
]

export const cyberSecurityBenefit = [
    {'title': 'Enhanced Protection', 'description': 'Defend against cyber threats with advanced security measures, including firewall management, intrusion detection/prevention, and real-time threat monitoring'},
    {'title': 'Risk Mitigation', 'description': 'Identify and mitigate cybersecurity risks with regular assessments, vulnerability scans, and proactive security strategies.'},
    {'title': 'Compliance Assurance', 'description': 'Stay compliant with regulatory requirements such as GDPR, HIPAA, and PCI-DSS through our compliance-focused cybersecurity solutions.'},
    {'title': 'Business Continuity', 'description': 'Ensure uninterrupted operations with robust disaster recovery and business continuity plans tailored to your business needs.'},

]

export const networkSupportData = [
    {'title': 'We employ advanced tools and technologies to provide real-time detection and proactive response to cyber threats, ensuring comprehensive protection.'},
    {'title': 'Enhance your network’s performance and security with our customized upgrades of devices, software, and infrastructure components to meet evolving business needs.'},
    {'title': 'Our experts conduct comprehensive diagnostics to pinpoint network issues and recommend optimized solutions for enhanced performance and reliability.'},
    {'title': 'Protect your data with our network support services, where we deploy robust security measures to defend against cyber threats.'},
    {'title': 'Optimize your network’s efficiency through our fine-tuning efforts, minimizing latency and maximizing data transfer speeds to ensure peak operational performance.'},
]

export const microsoftServiceBenefit = [
    {'title': 'Empower your team to work seamlessly from anywhere with familiar tools like Word, Excel, and Teams.', 'icon': eye},
    {'title': 'Boost efficiency with cloud-based access to documents and applications from any device', 'icon': eye},
    {'title': 'Scale your IT resources effortlessly as your business grows, with flexible subscription plans.', 'icon': eye},
    {'title': 'Ensure data security with built-in compliance features and advanced threat protection offered by Microsoft 365.', 'icon': eye},
    {'title': 'Reduce IT costs with a pay-as-you-go model and eliminate the need for on-premises infrastructure maintenance.', 'icon': eye},
]

export const securityCameraService = [
    {'title': 'Protect your home with strategically placed security cameras that cover all vulnerable areas.', 'icon': eye},
    {'title': 'Secure your business premises with high-resolution cameras, ensuring 24/7 monitoring', 'icon': eye},
    {'title': 'Tailored security setups to meet your unique needs and preferences.', 'icon': eye},
    {'title': 'Keep your system up-to-date with our regular maintenance and upgrade services.', 'icon': eye},
    {'title': 'Keep your system up-to-date with our regular maintenance and upgrade services.', 'icon': eye},
]

export const cyberSecurityService = [
    {'title': 'Proactive monitoring and rapid response to cyber incidents to minimize impact', 'icon': eye},
    {'title': 'Protect endpoints (devices) from malware, ransomware, and unauthorized access.', 'icon': eye},
    {'title': 'Secure sensitive data with encryption and data loss prevention (DLP) solutions.', 'icon': eye},
    {'title': 'Educate employees on cybersecurity best practices to reduce human error and enhance security awareness.', 'icon': eye},
    {'title': 'Ensure compliance with industry regulations and manage cybersecurity risks effectively.', 'icon': eye},

]

export const networkSupportService = [
    {'title': 'Network Device Replacement', 'description': 'Keep your network running smoothly with our quick and efficient device replacement services. We handle everything from routers and switches to firewalls and access points.', icon: digital},
    {'title': 'Infrastructure Upgrades', 'description': 'Upgrade your network infrastructure to meet the demands of modern business. Our experts assess your current setup and implement cutting-edge solutions to enhance performance and security.', icon: performance},
    {'title': '24/7 Network Monitoring', description: 'Stay ahead of potential issues with our round-the-clock network monitoring. We proactively identify and resolve problems before they impact your operations.', icon: monitoring},
    {'title': 'Network Security', description: 'Protect your network from cyber threats with our comprehensive security solutions. We provide firewalls, intrusion detection systems, and regular security audits.', icon: secure},

]

export const cloudMigrationService = [
    {'title': 'Data Migration', 'description': 'Safely and efficiently migrate your data to the cloud, ensuring data integrity and minimal disruption to your operations.', icon: dataProcessing},
    {'title': 'Application Migration', 'description': 'Move your applications to the cloud with minimal downtime, ensuring they perform optimally in the new environment.', icon: dataTransfer},
    {'title': 'Infrastructure Migration', description: 'Transition your entire IT infrastructure to the cloud, taking advantage of the scalability and flexibility of cloud solutions.', icon: cloudBackup},
    {'title': 'Cloud Security', description: 'Protect your cloud environment with our comprehensive security solutions, including encryption, access controls, and regular security audits.', icon: webSecurity},

]

export const technicalSupportService = [
    {'title': '24/7 Help Desk Support', 'description': 'Our help desk is available around the clock to assist with any technical issues, providing immediate support to keep your business running smoothly.', icon: deskSupport},
    {'title': 'Remote and On-Site Support', 'description': 'We offer both remote and on-site support services, ensuring that your issues are resolved quickly, no matter where you are located.', icon: remoteSupport},
    {'title': 'Hardware and Software Troubleshooting', description: 'Our experts diagnose and fix hardware and software issues, ensuring your technology api effectively.', icon: troubleshooting},
    {'title': 'System Setup and Configuration', description: 'We assist with the setup and configuration of new systems, ensuring they are optimized for your business needs.', icon: configuration},
    {'title': 'Network Support', description: 'Our team provides comprehensive network support, from troubleshooting connectivity issues to optimizing network performance.', icon: networkSupport},
    {'title': 'Virus and Malware Removal', description: 'Protect your systems from threats with our virus and malware removal services, ensuring your data remains secure.', icon: malwareSupport},
    {'title': 'Data Backup and Recovery', description: 'We provide robust data backup and recovery solutions, safeguarding your critical data against loss.', icon: dataBackup},
    {'title': 'IT Training and Support', description: 'Our training sessions help your staff become proficient with new technologies, ensuring they can utilize your IT infrastructure effectively.', icon: training},

]


