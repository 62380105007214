import {FC, useEffect, useState} from "react";
import {Layout} from "../../components/Layout";
import { cyberSecurityService } from "../../dataSet/Services";
import * as animationData from "../../assets/lottie/security.json";
import Lottie from "react-lottie";
import Assessment from "../../assets/images/risk-assessment.svg";
import Cyber from "../../assets/images/cefriel-servizi-cybersecurity-2x.png";
import Expert from "../../assets/images/expert.png";
import Consultation from "../../assets/images/consultation.svg";
import Installation from "../../assets/images/install.svg";
import Configuration from "../../assets/images/configuration.svg";
import Support from "../../assets/images/24-hours-service.svg";
import ActionWidget from "../../components/ActionWidget";

const steps = [
    {title: '1. Consultation', description: 'Our experts will meet with you to assess your current cybersecurity posture and identify areas of vulnerability.', imgSrc: Consultation},
    {title: '2. Risk Assessment and Analysis', description: 'We perform a comprehensive analysis of your IT infrastructure to pinpoint weaknesses and evaluate the potential impact of various cyber threats.', imgSrc: Assessment},
    {title: '3. Customized Security Plan Development', description: 'We create a customized security plan that includes policies, procedures, and technologies designed to protect your digital assets.', imgSrc: Installation},
    {title: '4. Implementation of Security Solutions', description: 'Our team deploys cutting-edge security technologies to safeguard your network, data, and applications.', imgSrc: Configuration},
    {title: '5. Employee Training and Awareness', description: 'We conduct regular training programs to ensure your employees are equipped with the knowledge to prevent and respond to cyber attacks.', imgSrc: Support},
    {title: '6. Continuous Monitoring and Incident Response', description: 'Our monitoring team keeps a vigilant eye on your systems, ready to act swiftly to mitigate any security incidents.', imgSrc: Support},
    {title: '7. Regular Audits and Updates', description: 'We conduct periodic reviews and updates of your security measures to adapt to the latest threat landscape', imgSrc: Support},
    {title: '8. Ongoing Support and Maintenance', description: 'We offer ongoing support to address any issues and ensure your cybersecurity infrastructure remains strong and resilient.', imgSrc: Support},
];

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const CyberSecurity: FC = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setActiveIndex(prevStep => (prevStep + 1) % steps.length);
        }, 3000);

        return () => clearInterval(timer);
    }, []);

    return (
        <Layout>
            {/*Hero Section*/}
            <section className={'widgetBanTxtBtnImg'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <div className={'page-banner'}>
                                <div className={'content'}>
                                    <h1 className="banner-title">Protect your business with our comprehensive
                                        cybersecurity services. </h1>
                                    <a href="/contact-us" target="_self" className="btn-solid bg-white">Get in touch</a>
                                </div>
                                <div className={'icon'}>
                                    <Lottie options={defaultOptions} height={350} width={350}/>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <div className={'separator'}/>

            <section className={'widgetColTxtImgBul'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-lg-8 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1'}>
                            <h2 className="sectionTitle text-md-start text-sm-center text-center">
                                We offer tailored
                                solutions to <span className="text-lightblue">safeguard your data, mitigate risks, and ensure compliance with industry standards.</span>
                            </h2>
                            <div className={'horizontalPoints'}>
                                {cyberSecurityService.map((benefit) => (
                                    <div className={'points'}>
                                        <div className={'icon'}>
                                            <span className={'iconwrap'}>
                                                <img src={benefit.icon} height={24} width={24} alt={'icon'}/>
                                            </span>
                                        </div>
                                        <div className={'text'}>
                                            <p className={'desc'}>{benefit.title}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={'col-lg-4 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2'}>
                            <div className={'bannercontainer'}>
                                <img src={Cyber} alt={'icon'}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={'widgetColrTxt'}>
                <div className={'container'}>
                    <div className={'bgBlue'}>
                        <div className={'row align-items-center justify-content-center'} style={{padding: 16}}>
                            <div className={'col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12'}>
                                <h2 className={'title text-xl-start text-lg-center text-md-center text-sm-center text-center'}>Our team of cybersecurity experts brings extensive experience
                                    and industry certifications to deliver proactive cybersecurity solutions. We specialize
                                    in protecting businesses across various sectors from emerging cyber threats.</h2>
                            </div>
                            <div className={'col-lg-4 d-lg-flex d-md-none d-sm-none d-none expertImg justify-content-center'}>
                                <img src={Expert} height={300} alt={'icon'}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={'widgetColStepCard'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <h2 className="sectionTitle text-center">
                            Our CyberSecurity process follows a, <span className="text-lightblue"> step-by-step approach that guarantees success</span>
                        </h2>
                        <p className="sectionDescription text-center">
                            Ensuring Robust Protection for Your Digital Assets</p>
                    </div>
                    <div className="row d-lg-flex d-md-flex d-sm-none d-none">
                        {steps.map((step) => (
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className={'icondatacard'}>
                                    <div className={'head'}>
                                        <div className={'icon'}>
                                            <img src={step.imgSrc} height={50} width={50} alt={'icon'}/>
                                        </div>
                                        <div className={'text'}>
                                            <h4>{step.title}</h4>
                                        </div>
                                    </div>
                                    <div className={'body'}>

                                    </div>
                                    <p className={'card-description'}>{step.description}</p>
                                </div>

                            </div>
                        ))}
                    </div>
                    <div className="row d-lg-none d-md-none d-sm-flex d-flex">
                        <div className="col">
                            <div className="carousel slide carousel-fade">
                                <div className="carousel-inner">
                                    {steps.map((step, index) => (
                                        <div className={`carousel-item ${activeIndex === index ? 'active': ''}`}>
                                            <div className="iconTitleDescCard">
                                                <div className="head">
                                                    <div className="icon">
                                                        <img src={step.imgSrc} height={50} width={50} alt={'icon'}/>
                                                    </div>
                                                    <div className="text">
                                                        <h3 className="title">{step.title}</h3>
                                                    </div>
                                                </div>
                                                <div className="tail">
                                                    <p className="desc">{step.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ActionWidget
                text={'Ready to strengthen your cybersecurity defenses? Contact us today for a consultation and take proactive steps to protect your business from cyber threats.'}
                sectionStyle={{marginTop: 40}}
            />
        </Layout>
    )
}
