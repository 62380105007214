import {HeroBannerWidget} from "../components/HeroBanerWidget";
import {ServiceCard} from "../components/ServiceCard";
import {Layout} from "../components/Layout";
import {servicesGroup} from "../dataSet/Services";
import {Carousel} from "../components/Carousel";
import {ClientTestimonials} from "../dataSet/ClientTestimonials";
import './HomePage.css'
// @ts-ignore
import Rock from '../assets/images/lego-2539844_1920.jpg'
import ActionWidget from "../components/ActionWidget";

const HomePage = () => {
    return (
        <Layout>
            <HeroBannerWidget
                heroUrl={Rock}
                content={{
                   subtitle: 'IT Servcies and Solutions',
                   title: 'Let\'s Navigate Your Technology Decisions Together.'
            }}/>
            {/* Service Widget START */}
            <section style={{paddingTop: 60, paddingBottom: 36}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12" style={{marginBottom: 40, textAlign: 'center'}}>
                            <p className={'sectionDescription text-center'} style={{marginBottom: 20}}>Industries we Serve</p>
                            <h3 className={'sectionTitle text-center'}>For your very specific industry,<br/>
                                we have <span className={'text-navyBlue'}>highly-tailored IT solutions.</span></h3>
                        </div>
                        {servicesGroup.map(service => <ServiceCard data={service}/>)}
                    </div>
                </div>
            </section>
            {/* Service Widget END */}
            {/* Testimonials Widget START */}
            <section style={{paddingTop: 60, backgroundColor: '#F9F9FE'}}>
                <div className={'container'}>
                    <h2 className={'sectionTitle text-center'}>Featured <span style={{color: '#035370'}}>customer testimonials</span></h2>
                    <p className={'sectionDescription text-center'}>Hear directly from our satisfied customers who have
                        experienced the transformative power of our services. Learn how we have helped them overcome
                        challenges, drive innovation, and achieve remarkable results.</p>
                </div>
                <Carousel data={ClientTestimonials}/>
            </section>
            {/* Testimonials Widget END */}
            {/* Banner Widget START */}
            <ActionWidget
                text={'"I\'m here to support our business\'s growth and success through collaborative efforts and innovative ideas."'}
                sectionStyle={{marginTop: 40}}
            />
            {/* Banner Widget END */}
        </Layout>
    );
}
export default HomePage;
