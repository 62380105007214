import './index.css';

export const Footer = () => {
    return (
        <footer className="footer">
            <div className="top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            At KamTech Solutions, we are passionate about empowering businesses through technology.
                            With KamTech Solutions by your side, you can navigate the ever-evolving IT landscape with confidence, knowing that your goals are our top priority.
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12"></div>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="top-content">
                                <div className="privacy">
                                    <p className="copyright">
                                        © 2024 KamTech All Rights Reserved
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
