import { FC } from "react";
import './index.css'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel as RNCarousel } from 'react-responsive-carousel';
import Quote from '../../assets/images/quotation-mark-7228448.svg'

interface IProps {
    data: Array<any>
}

export const Carousel: FC<IProps> = ({data}) => {
    return (
        <RNCarousel autoPlay interval={5000} infiniteLoop>
                {data.map((item) => {
                    return (
                        <div className={'carousel-item row'} style={{height: 400}}>
                            <div className={'col-lg-6 bg-white'} style={{borderRadius: 8}}>
                                <div style={{padding: 26}}>
                                    <div style={{display: 'flex'}}>
                                        <img src={Quote} height={24} width={24} style={{flex: 0, marginRight: 4}} alt={'logo'}/>
                                        <p style={{fontSize: 18, textAlign: 'justify'}}>{item.description}</p>
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'end'}}>
                                    <p style={{fontWeight:'bold'}}>~ {item.role}, {item.name}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={'col-lg-4'}>
                                <img src={item.logo} alt={'logo'} />
                            </div>
                        </div>
                    )
                })}
        </RNCarousel>
    )
}
