import './index.css'

interface HeroBannerWidgetProps {
    heroUrl?: string
    content?: {
        title: string;
        subtitle: string;
    },
    imageStyle?: any
}

export const HeroBannerWidget = (props: HeroBannerWidgetProps) => {
    const {heroUrl, content, imageStyle} = props;
    return (
        <section className="hero-banner-widget">
            <img src={heroUrl} className="banner-img" alt={'img'} style={imageStyle} />
            {!!content && <div className="overlayContent">
                <div className="container">
                    <div className="textArea">
                        <h1>{content.title}</h1>
                        <p>{content.subtitle}</p>
                    </div>
                </div>
            </div>}
        </section>
    )
}
