import Trusted from '../assets/images/business-1049272_1280.png';
import Handshake from '../assets/images/handshake-8683757_1280.png';

export const ClientTestimonials = [
    {
        name: 'The Happyness Center',
        role: 'President',
        description: 'Our partnership with KamTech Solutions has been invaluable. Their commitment to supporting nonprofits like ours is truly exceptional. With their expert IT solutions, our organization has seen a significant improvement in our operational efficiency, allowing us to focus more on our mission.',
        logo: Handshake
    },
    {
        name: 'WRD Media',
        role: 'CEO',
        description: 'Our experience with KamTech Solutions has been outstanding. Their prompt support and proactive approach to managing our IT infrastructure have not only improved our operational efficiency but also provided us with peace of mind. We trust them to keep our technology running smoothly without fail.',
        logo: Trusted
    }
]
