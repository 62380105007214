import React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import HomePage from "./screens/HomePage";
import {CyberSecurity} from "./screens/services/CyberSecurity";
import {CloudMigration} from "./screens/services/CloudMigration";
import {MicrosoftService} from "./screens/services/MicrosoftService";
import {NetworkSupport} from "./screens/services/NetworkSupport";
import {TechnicalSupport} from "./screens/services/TechnicalSupport";
import {SecurityCameras} from "./screens/services/SecurityCameras";
import {NonProfits} from "./screens/services/NonProfits";
import ContactUs from "./screens/ContactUs";
import NotFound from "./screens/NotFound";

const App: React.FC = () =>  {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/services/cybersecurity" element={<CyberSecurity />} />
          <Route path="/services/cloud-migration" element={<CloudMigration />} />
          <Route path="/services/microsoft-service" element={<MicrosoftService />} />
          <Route path="/services/network-security" element={<NetworkSupport />} />
          <Route path="/services/technical-support" element={<TechnicalSupport />} />
          <Route path="/services/security-cameras" element={<SecurityCameras />} />
          <Route path="/services/non-profits" element={<NonProfits />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route element={<NotFound />} />
        </Routes>
      </Router>
  )
}

export default App;
