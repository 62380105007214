import {FC} from "react";
import {Layout} from "../../components/Layout";
import NonProfit from "../../assets/images/non-profit-organization-1.webp";
import Assessment from "../../assets/images/risk-assessment.svg";
import Planning from "../../assets/images/planning.svg";
import Execution from "../../assets/images/work.svg";
import MoneyBag from "../../assets/images/moneybags-30556.svg";
import statistics from "../../assets/images/statistics-4623853_1280.png";
import ActionWidget from "../../components/ActionWidget";
import profit from "../../assets/images/money-40603_1280.png";


const steps = [
    {title: 'Cost Savings', description: 'Access to discounted or free IT products and services can significantly lower your IT expenses, allowing you to allocate more funds directly to your mission.', imgSrc: Assessment},
    {title: 'Increased Efficiency', description: 'Implement modern IT solutions to enhance your organization\'s capabilities and efficiency.', imgSrc: Planning},
    {title: 'Mission Focus', description: 'By reducing the financial burden of IT infrastructure, your organization can invest more in programs and services that matter most.', imgSrc: Execution},
];

const approach = [
    {title: 'Customized Solutions', description: 'We collaborate with each non-profit to deeply understand their unique needs, delivering services that are precisely tailored to meet those requirements.', imgSrc: Assessment},
    {title: 'Budget-Friendly Options', description: 'We offer cost-effective solutions and competitive pricing, ensuring that non-profits can maximize their limited resources', imgSrc: Planning},
    {title: 'Scalable IT Solutions', description: 'Our solutions are designed to grow with your organization, providing the flexibility to expand without the need for frequent infrastructure overhauls.', imgSrc: Execution},
    {title: 'Proactive IT Support', description: 'We focus on proactive support, addressing potential IT issues before they become problems, ensuring your operations run smoothly.', imgSrc: Execution},
];

export const NonProfits: FC = () => {

    return (
        <Layout>
            <div className={'row d-lg-flex d-md-none d-sm-none d-none'}>
                <img src={NonProfit} className="banner-img" alt={'img'}/>
            </div>
            <section className={'widgetBanTxtBtnImg d-lg-none d-md-flex d-sm-flex d-flex'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <div className={'page-banner'}>
                                <div className={'content'}>
                                    <h1 className="banner-title">
                                        IT Service for Non Profits</h1>
                                    <p className={'subtitle'}>Transform Your Business with Efficient and Secure Solutions</p>
                                    <a href="/contact-us" target="_self" className="btn-solid bg-white">Get in touch</a>
                                </div>
                                <div className={'icon-animation'}>
                                    <img src={profit} height={300} width={300} alt={'icon'}/>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <div className={'separator'}/>
            <section className={'widgetColTxtImgBul'}>
                <div className={'container'}>
                    <h2 className='sectionTitle text-center'>Technology Grants for Non-Profits</h2>
                    <div className={'row'}>
                        <div className={'col-lg-8 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1'}>
                            <p style={{fontSize: 20}}>
                                Many leading tech companies offer grants and discounts to help non-profit organizations
                                access essential IT services and solutions at a reduced cost. KamTech Solutions is here
                                to guide non-profits through the grant application process, ensuring they can take full
                                advantage of these valuable resources.
                            </p>
                            <h3 className={'mt-5'} style={{marginBottom: 20}}>Why These Grants Matter:</h3>
                            {steps.map((item) => (
                                <p><p style={{
                                    color: '#2B58A4',
                                    marginBottom: 2,
                                    fontWeight: 'bold'
                                }}>{item.title}</p>{item.description}</p>
                            ))}
                        </div>
                        <div className={'col-lg-4 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2'}>
                            <div className={'bannercontainer'}>
                                <img src={MoneyBag} height={400} alt={'icon'}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
                <section className={'widgetColCard'} >
                    <div className={'container container-padding'}>
                        <h2 className={'sectionTitle text-center'}>Our Approach</h2>
                        <div className={'row justify-content-between align-items-center'}>
                            <div className={'col-lg-8 col-md-12'}>
                                <div className={'row d-lg-flex d-md-flex'}>
                                    {approach.map((item) => (
                                        <div className={'col-lg-6 col-md-6 col-sm-12 col-12'}>
                                            <div className="datacard">
                                                <h3 className="title">{item.title}</h3>
                                                <p className="desc">{item.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                            <div className={'col-lg-4 d-lg-flex d-md-none d-sm-none d-none'}>
                                <img src={statistics} height={500} width={500} alt={'icon'}/>
                            </div>
                        </div>
                    </div>
                </section>
                <ActionWidget
                    text={'Our mission is to deliver tailored IT solutions that enable non-profits to focus on their core mission without being bogged down by technology concerns. Contact us today for a consultation and discover how we can transform your workplace.'}
                    sectionStyle={{marginTop: 30}}
                />
        </Layout>
    )
}
