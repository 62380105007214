import {FC, useEffect, useState} from "react";
import {Layout} from "../../components/Layout";
import {technicalSupportService} from "../../dataSet/Services";
import Assessment from "../../assets/images/risk-assessment.svg";
import Planning from "../../assets/images/planning.svg";
import Execution from "../../assets/images/work.svg";
import Support from "../../assets/images/support.svg";
import ActionWidget from "../../components/ActionWidget";
import Lottie from 'react-lottie';
import * as animationData from '../../assets/lottie/technical.json'
import Team from "../../assets/images/build.jpg";


const steps = [
    {title: 'Experienced IT Specialists', description: 'Our team consists of certified IT specialists with extensive experience in providing technical support.', imgSrc: Assessment},
    {title: 'Quick Response Time', description: 'We prioritize quick response times to minimize downtime and keep your business running smoothly.', imgSrc: Planning},
    {title: 'Customized Support Plans', description: 'We offer tailored support plans to meet the specific needs of your business.', imgSrc: Execution},
    {title: 'Customer-Centric Service', description: 'We prioritize your satisfaction, providing personalized support and dedicated account managers.', imgSrc: Support},
];

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const TechnicalSupport: FC = () => {
    const [activeService, setActiveService] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setActiveService(prevStep => (prevStep + 1) % technicalSupportService.length);
        }, 5000);

        return () => clearInterval(timer);
    }, []);

    return (
        <Layout>
            <section className={'widgetBanTxtBtnImg'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <div className={'page-banner'}>
                                <div className={'content'}>
                                    <h1 className="banner-title">
                                        Reliable Technical Support Services for Your Business</h1>
                                    <p className={'subtitle'}>Ensuring Your Technology Runs Smoothly and Efficiently</p>
                                    <a href="/contact-us" target="_self" className="btn-solid bg-white">Get in touch</a>
                                </div>
                                <div className={'icon'}>
                                    <Lottie options={defaultOptions} height={400} width={400}/>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
            <div className={'separator'}/>
            <section className={'widgetColServiceCard'}>
                <div className={'container'}>
                    <h2 className={'sectionTitle text-center'}>Technical Support Services</h2>
                    <p className={'sectionDescription text-center'}>FFast, Reliable, and Expert Solutions for Your IT
                        Needs</p>
                    <div className={'row d-lg-flex d-md-flex d-sm-none d-none'}>
                        {technicalSupportService.map(service => (
                            <div className={'col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 mb-4'}>
                                <div className={'card tech-card'} style={{padding: 10, alignItems: 'center'}}>
                                    <img src={service.icon} height={200} width={200} alt={'icon'}/>
                                    <div style={{marginTop: 10, height: 200}}>
                                        <h5 style={{textAlign: 'center'}}>{service.title}</h5>
                                        <p style={{textAlign: 'center'}}>{service.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                        }
                    </div>
                    <div className={'row d-lg-none d-md-none d-sm-flex d-flex'}>
                        <div className={'col'}>
                            <div className={'carousel slide carousel-fade'}>
                                <div className={'carousel-inner'}>
                                    {technicalSupportService.map((service, index) => (
                                        <div className={`card card-shadow carousel-item ${activeService === index ? 'active': ''}`} style={{height: 400}}>
                                            <div className={'icon'} style={{justifyContent: 'center', display: 'flex'}}>
                                                <img src={service.icon} style={{width: '50%'}} height={200} alt={'icon'}/>
                                            </div>
                                            <div style={{margin: 10, height: 200}}>
                                            <h5 style={{textAlign: 'center'}}>{service.title}</h5>
                                                    <p style={{textAlign: 'center'}}>{service.description}</p>
                                                </div>
                                        </div>
                                    ))
                                    }
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </section>
            <section className={'widgetColCard'}>
                <div className={'container'}>
                    <h2 className={'sectionTitle text-center'}>Why Choose KamTech Solutions for Technical Support?</h2>
                    <p className={'sectionDescription text-center'}>Expertise, Reliability, and Dedicated IT
                        Solutions</p>
                    <div className={'row mt-5 justify-content-between align-items-center'}>
                        <div className={'col-lg-8 col-md-12'}>
                            <div className={'row d-lg-flex d-md-flex'}>
                                {steps.map((item) => (
                                    <div className={'col-lg-6 col-md-6 col-sm-12 col-12'}>
                                        <div className="datacard">
                                        <h3 className="title">{item.title}</h3>
                                            <p className="desc">{item.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                        <div className={'col-lg-3 d-lg-flex d-md-none d-sm-none d-none'}>
                            <img src={Team} height={500} style={{borderRadius: 24}} alt={'icon'}/>
                        </div>
                    </div>
                </div>
            </section>
            <ActionWidget
                text={'Looking for Dependable and Reliable Technical Support? Get in Touch with Us Today for a Free Consultation.'}
                sectionStyle={{marginTop: 30}}
            />
        </Layout>
    )
}
