import {FC} from "react";
import {Layout} from "../../components/Layout";
import {microsoftServiceBenefit} from "../../dataSet/Services";
import Stepper from "../../components/Stepper";
import Assessment from "../../assets/images/risk-assessment.svg";
import Planning from "../../assets/images/planning.svg";
import Execution from "../../assets/images/work.svg";
import Support from "../../assets/images/support.svg";
import Compliance from "../../assets/images/compliance.png";
import Expert from "../../assets/images/expert.png";
import ActionWidget from "../../components/ActionWidget";
import Lottie from 'react-lottie';
import * as animationData from '../../assets/lottie/microsoft.json'
import * as TeamData from '../../assets/lottie/team.json'
import lock from '../../assets/images/padlock-308589.svg'


const steps = [
    {title: 'Assessment', description: 'Conduct a thorough analysis of your current IT environment and business needs.', imgSrc: Assessment},
    {title: 'Planning', description: 'Develop a customized migration plan, including timeline and resource allocation.', imgSrc: Planning},
    {title: 'Execution', description: 'Execute the migration with minimal disruption to your business operations.', imgSrc: Execution},
    {title: 'Support', description: 'Provide ongoing support and training to ensure a smooth transition and maximize adoption.', imgSrc: Support},
];

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: TeamData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const MicrosoftService: FC = () => {

    return (
        <Layout>
            <section className={'widgetBanTxtBtnImg'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <div className={'page-banner'}>
                                <div className={'content'}>
                                    <h1 className="banner-title">
                                        Transform your business with our expert Microsoft 365 setup and migration services. </h1>
                                    <a href="/contact-us" target="_self" className="btn-solid bg-white">Get in
                                        touch</a>
                                </div>
                                <div className={'icon'}>
                                    <Lottie options={defaultOptions1} height={400} width={300}/>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
            <div className={'separator'}/>
            <section className={'widgetColTxtImgBul'}>
                <div className={'container'}>
                    <h2 className={'sectionTitle text-center'}>Microsoft 365 Setup and Migration Services</h2>
                    <p className={'sectionDescription text-center'}>Whether
                            you’re looking to transition to the cloud, streamline collaboration, or enhance
                            productivity, we
                            provide tailored solutions to meet your needs.</p>
                    <div className={'container'}>
                        <div className={'row justify-content-around'}>
                            <div className={'col-lg-4 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2'}>
                                <Lottie options={defaultOptions}/>
                            </div>
                            <div className={'col-lg-8 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1'}>
                                <div className={'horizontalPoints'}>
                                    {microsoftServiceBenefit.map((benefit) => (
                                        <div className={'points'}>
                                            <div className={'icon'}>
                                            <span className={'iconwrap'}>
                                                <img src={benefit.icon} height={24} width={24} alt={'icon'}/>
                                            </span>
                                            </div>
                                            <div className={'text'}>
                                                <p className={'desc'}>{benefit.title}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={'widgetColrTxt'}>
                <div className={'container'}>
                    <div className={'bgBlue'}>
                        <div className={'row align-items-center justify-content-center'} style={{padding: 16}}>
                            <div className={'col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12'}>
                                <h2 className={'title text-xl-start text-lg-center text-md-center text-sm-center text-center'}>Our
                                    certified Microsoft 365 specialists have a proven track
                                    record of successful
                                    migrations
                                    across various industries. We stay updated with the latest Microsoft technologies to
                                    deliver
                                    seamless transitions and optimal solutions tailored to your business
                                    requirements.</h2>
                            </div>
                            <div className={'col-lg-4 d-lg-flex d-md-none d-sm-none d-none expertImg justify-content-center'}>
                                <img src={Expert} height={300} alt={'icon'}/>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section className={'widgetStepper'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <h2 className="sectionTitle text-center">Our migration process follows a <span className="text-lightblue">proven, step-by-step approach that guarantees success</span></h2>
                        <p className="sectionDescription text-center">We provide a complete Microsoft 365 migration service tailored to meet the needs of businesses across diverse industries </p>
                    </div>
                    <div className={'row d-flex justify-content-center'}>
                        <div className={'col-lg-12 d-lg-flex d-md-none d-sm-none d-none'} style={{height: 250}}>
                            <Stepper steps={steps} interval={3000} isMobileView={false}/>
                        </div>
                        <div className={'col-md-6 d-lg-none'} style={{alignSelf: 'center'}}>
                            <Stepper steps={steps} interval={3000} isMobileView/>
                        </div>
                    </div>
                </div>
            </section>
            <section className={'widgetCompliance'}>
                <div className={'container'}>
                    <div className={'row align-items-center justify-content-center'} style={{padding: 16}}>
                        <div className={'col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12'}>
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <img src={lock} height={24} width={24}
                                             style={{marginBottom: 6, marginRight: 8}} alt={'icon'}/>
                                        <h2>Security and Compliance</h2>

                                    </div>
                                    <p style={{fontSize: 20, marginTop: 40}}>
                                        Rest assured, your data is protected with Microsoft 365’s built-in security
                                        features,
                                        including
                                        data encryption, multi-factor authentication, and compliance with industry
                                        regulations like
                                        GDPR
                                        and HIPAA.
                                    </p>
                                </div>
                                <div className={'col-lg-4 d-lg-flex d-md-none d-sm-none d-none expertImg justify-content-center'}>
                                    <img src={Compliance} height={400} alt={'icon'}/></div>
                    </div>
                </div>
            </section>
            <ActionWidget
                text={'Ready to elevate your business with Microsoft 365? Contact us today for a consultation and discover how we can transform your workplace.'}
                sectionStyle={{marginTop: 30}}
            />
        </Layout>
)
}
