import {Layout} from "../../components/Layout";
import './index.css'
import React, {useState} from "react";
import { toast } from 'react-toastify';
import Loader from "../../components/Loader";
import Call from '@mui/icons-material/Call';
import Location from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';

const initialFormData = {
    name: '',
    email: '',
    phone: '',
    message: ''
};

const contactData = [
    {title: 'Address', subTitle: 'Kamloops, BC V2C 2V2', icon: <Location fontSize="large" />},
    {title: 'Phone', subTitle: '+1 (778) 902-2585', icon: <Call fontSize="large" />},
    {title: 'Mail', subTitle: 'info@kamtechsolutions.ca', icon: <EmailIcon fontSize="large" />},
]

const ContactUs = () => {
    const [formData, setFormData] = useState(initialFormData);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const onSubmitForm = async (e: any) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const response = await fetch('/api/sendEmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                toast.success('Email sent successfully!');
                setFormData(initialFormData); // Clear the form
            } else {
                toast.error('Failed to send email. Please try again later.');
            }
        } catch (error) {
            toast.error('Failed to send email. Please try again later.');
        } finally {
        setIsSubmitting(false);
    }
    }

    return (
        <Layout>
            {isSubmitting && <Loader/>}
            <section className={'widgetBannerBgImg'} style={{backgroundSize: 'cover'}}>
                <div className={'gradientCover'}>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className={'page-banner'}>
                                    <div className={'content'}>
                                        <h1 className={'title'}>Contact Us</h1>
                                        <p className={'subtitle'}>
                                            We’re always excited to hear from you. If something about us has piqued your
                                            interest and you would like to find out more, please feel free to reach out
                                            to us.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={'widgetColServiceCard'}>
                <div className={'container'}>
                    <h2 className={'sectionTitle text-center'}>Contact Us</h2>
                    <p className={'sectionDescription text-center'}>We listen, understand, and guide you</p>
                </div>
                <div className={'row justify-content-center mt-5'}>
                    {contactData.map((item) => (
                        <div className={'tech-card'} style={{width: 300, marginRight: 24}}>
                        <div className={'contact-card'} style={{
                            border: '0.5px solid #A1ACC3',
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                            borderRadius: 8,
                            flexDirection: 'column',
                            marginTop: 16
                        }}>
                            <div style={{padding: 10}}>
                                {item.icon}
                            </div>
                            <div style={{marginTop: 8}}>
                                <h5 className={'title text-center'}>{item.title}</h5>
                                <p className={'subtitle text-center'}>{item.subTitle}</p>
                            </div>
                        </div>
                        </div>
                    ))}
                </div>
            </section>
            <div className={'container'}>
                <div style={{height: 1, border: '1px dashed', opacity: 0.5}} />
            </div>
            <section className={'widgetContactForm'}>
                <div className={'container'}>
                    <div className={'row justify-content-center'}>
                        <div className={'col-xl-6 col-lg-8 col-md-12 col-sm-12 col-12'}>
                            <div className={'contactForm'}>
                                <form onSubmit={onSubmitForm} method="POST">
                                    <div className={'inputGroup'}>
                                        <label htmlFor={'name'} className={'formLabel'}>Name*</label>
                                        <span data-name={'name'}>
                                            <input value={formData.name} className={'form-control'} name={'name'}
                                                   placeholder={'Enter your name'} required onChange={handleChange}
                                                   disabled={isSubmitting}/>
                                        </span>
                                    </div>
                                    <div className={'inputGroup'}>
                                        <label htmlFor={'email'} className={'formLabel'}>Email*</label>
                                        <span data-name={'email'}>
                                            <input value={formData.email} className={'form-control'} name={'email'}
                                                   type='email' placeholder={'Enter your email address'} required
                                                   onChange={handleChange} disabled={isSubmitting}/>
                                        </span>
                                    </div>
                                    <div className={'inputGroup'}>
                                        <label htmlFor={'phone'} className={'formLabel'}>Phone*</label>
                                        <span data-name={'phone'}>
                                            <input value={formData.phone} className={'form-control'} name={'phone'}
                                                   placeholder={'Enter your phone number'} required type={'tel'}
                                                   onChange={handleChange} disabled={isSubmitting}/>
                                        </span>
                                    </div>
                                    <div className={'inputGroup'}>
                                        <label htmlFor={'message'} className={'formLabel'}>How we can help?*</label>
                                        <span data-name={'message'}>
                                            <textarea cols={40} value={formData.message} className={'form-control'}
                                                      name={'message'} placeholder={'Enter your message'} required
                                                      onChange={handleChange} disabled={isSubmitting} maxLength={400}/>
                                        </span>
                                    </div>
                                    <div className={'button-group'}>
                                        <button className="btn-solid btn-full bg-lightblue" name="submit" type="submit"
                                                disabled={isSubmitting}>
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default ContactUs;
