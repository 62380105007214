import {FC, useEffect, useState} from "react";
import {Layout} from "../../components/Layout";
import './common.css';
import { cloudMigrationService } from "../../dataSet/Services";
import ActionWidget from "../../components/ActionWidget";
import technician from "../../assets/images/technician.svg";
import monitor from "../../assets/images/dashboard.svg";
import idea from "../../assets/images/idea.svg";
import customer from "../../assets/images/customer-representative.svg";
import * as animationData from "../../assets/lottie/creative-team.json";
import cybersecurity from "../../assets/images/illustration-cloud-network-sharing-concept.png";
import Lottie from "react-lottie";

const steps = [
    {title: 'Experienced Cloud Experts', description: 'Our certified technicians have extensive experience in network support and infrastructure management.', imgSrc: technician},
    {title: 'Proven Methodology', description: 'We proactively monitor and maintain your network to prevent issues before they arise.', imgSrc: monitor},
    {title: 'Cost-Effective Solutions', description: 'Our services are designed to provide maximum value, ensuring your network runs efficiently without breaking the bank.', imgSrc: idea},
    {title: 'Customer-Centric Approach', description: 'We prioritize your satisfaction, offering personalized support and dedicated account managers.', imgSrc: customer},
];

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};


export const CloudMigration: FC = () => {
    const [activeService, setActiveService] = useState(0);
    const [activeStepIndex, setActiveStepIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setActiveService(prevStep => (prevStep + 1) % cloudMigrationService.length);
        }, 2000);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            setActiveStepIndex(prevStep => (prevStep + 1) % steps.length);
        }, 3000);

        return () => clearInterval(timer);
    }, []);

    return (
        <Layout>
            <section className={'widgetBanTxtBtnImg'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <div className={'page-banner'}>
                                <div className={'content'}>
                                    <h1 className="banner-title">
                                        Seamless Cloud Migration Services</h1>
                                    <p className={'subtitle'}>Transform Your Business with Efficient and Secure Cloud Solutions</p>
                                    <a href="/contact-us" target="_self" className="btn-solid bg-white">Get in touch</a>
                                </div>
                                <div className={'icon-animation'}>
                                    <img src={cybersecurity} height={300} width={400} alt={'icon'} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <div className={'separator'}/>
            <section className={'widgetColServiceCard'}>
                <div className={'container'}>
                    <h2 className={'sectionTitle text-center'}>Cloud Migration Services</h2>
                    <p className={'sectionDescription text-center'}>Tailored Cloud Solutions to Meet Your Business Needs</p>
                    <div className={'row'}>
                        {cloudMigrationService.map((service, index) => (
                            <div className={'col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 mb-4'} >
                                <div className={`card ${activeService === index ? 'shadow-style' : ''}`} style={{padding: 10, alignItems: 'center'}}>
                                    <img src={service.icon} height={100} width={100} alt={'icon'}/>
                                    <div style={{marginTop: 10}}>
                                        <h5 style={{textAlign: 'center'}}>{service.title}</h5>
                                        <p style={{textAlign: 'center', height: 100}}>{service.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                        }
                    </div>
                </div>
            </section>
            <section className={'widgetColCard'}>
                <div className={'container'}>
                    <h2 className={'sectionTitle text-center'}>Why Choose KamTech Solutions for Cloud Migration?</h2>
                    <p className={'sectionDescription text-center'}>Expertise, Reliability, and Tailored Solutions for
                        Your Cloud Journey</p>
                    <div
                        className={'row d-lg-flex d-md-flex d-sm-none d-none mt-5 justify-content-between align-items-center'}>
                        <div className={'col-lg-6 col-md-12'}>
                            <div className={'row d-lg-flex d-md-flex'}>
                                {steps.map((item) => (
                                    <div className={'col-lg-6 col-md-6 col-sm-12 col-12'}>
                                        <div className="datacard">
                                            <h3 className="title">{item.title}</h3>
                                            <p className="desc">{item.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                        <div className={'col-lg-6 d-lg-flex'}>
                            <Lottie options={defaultOptions} height={500} width={500}/>
                        </div>
                    </div>
                    <div className={'row d-lg-none d-md-none d-sm-flex d-flex'}>
                        <div className={'col'}>
                            <div className={'carousel slide carousel-fade'}>
                                <div className={'carousel-inner'}>
                                    {steps.map((item, index) => (
                                        <div className={`carousel-item ${activeStepIndex === index ? 'active': ''}`}>
                                            <div className="datacard">
                                                <h3 className="title">{item.title}</h3>
                                                <p className="desc">{item.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <ActionWidget
                text={'Ready to Transform Your Business with the Cloud?Contact Us Today for a Free Consultation.'}
                sectionStyle={{marginTop: 30}}
            />
        </Layout>
    )
}
