import React, {useState} from "react";
import './Header.css'
import {Logo} from "../Images/logo";

export const Header = () => {
    const [isToggle, setIsToggle] = useState<boolean>(false)
    const [isToggleMobile, setIsToggleMobile] = useState<boolean>(false)

    const handleClickEvent = (e: any) => {
        e.preventDefault();
        console.log(e.target.href, e.target.className)
        if(e.target.href) {
            document.location.href = e.target.href;
            return;
        };
        if(e.target.className === 'toggle-menu') {
            setIsToggle(prevState => !prevState);
        }

        if(e.target.className === 'menudrop') {
            setIsToggleMobile(prevState => !prevState);
        }
    }

    return (
        <header className="header">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6 col-8">
                        <div className="logo" onClick={() => document.location.href = '/'}>
                            <Logo />
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-6 col-sm-6 col-4">
                        {/*Desktop menu*/}
                        <div className="menu">
                            <ul className={'main-menu-list'}>
                                <li className="menu-item dropdown-center">
                                    <a className="menu-item-title dropdown-toggle" href="#" role="button"
                                       data-bs-toggle="dropdown" aria-expanded="false">
                                        Services
                                    </a>
                                    <ul className="dropdown-menu" style={{padding: 16}}>
                                        <li><a className="dropdown-item"
                                               href="/services/cybersecurity">CyberSecurity</a></li>
                                        <li><a className="dropdown-item" href="/services/cloud-migration">Cloud
                                            Migration</a></li>
                                        <li><a className="dropdown-item" href="/services/microsoft-service">Microsoft
                                            365</a></li>
                                        <li><a className="dropdown-item" href="/services/network-security">Network
                                            Support</a></li>
                                        <li><a className="dropdown-item" href="/services/technical-support">Technical
                                            Support</a></li>
                                        <li><a className="dropdown-item" href="/services/security-cameras">Security
                                            Cameras</a></li>
                                        <li><a className="dropdown-item" href="/services/non-profits">IT Service for
                                            Non-Profits</a></li>
                                    </ul>
                                </li>
                                {/*<li className="menu-item"><a className={'menu-item-title'}>About</a></li>*/}
                                <li className="menu-item"></li>
                                <li><a className={'contact-button'} href={'/contact-us'} target={'_self'}>Contact Us</a></li>
                            </ul>
                        </div>
                        {/*Mobile menu*/}
                        <div className="menu-bar">
                            <span onClick={handleClickEvent} className="toggle-menu"></span>
                        </div>
                        <div className="mobile-menu" style={{display: isToggle ? 'block' : 'none'}}>
                            <ul>
                                <li onClick={handleClickEvent} className="menu-item-has-children">
                                    <a href="#">Services</a>
                                    <ul className="subMenu" style={{display: isToggleMobile ? 'block' : 'none'}}>
                                        <li><a href="/services/cybersecurity" target="_self">CyberSecurity</a></li>
                                        <li><a href="/services/cloud-migration" target="_self">Cloud Migration</a></li>
                                        <li><a href="/services/microsoft-service" target="_self">Microsoft 365</a></li>
                                        <li><a href="/services/network-security">Network Support</a></li>
                                        <li><a href="/services/technical-support">Technical Support</a></li>
                                        <li><a href="/services/security-cameras">Security Cameras</a></li>
                                        <li><a href="/services/non-profits">IT Service for Non-Profits</a></li>
                                    </ul>
                                    <span className="menudrop"></span></li>
                                {/*<li><a href="" target="">About</a></li>*/}
                                <li><a href="/contact-us" target="_self">Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>

    )
}

