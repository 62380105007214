import React from "react";

interface IProps {
    text: string;
    sectionStyle?: any;
}

const ActionWidget = ({text, sectionStyle}: IProps) => {
    return (
        <section style={{...{paddingBottom: 36}, ...sectionStyle}}>
            <div className="container">
                <div className="bancontent">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-md-10 col-sm-12 col-12 text-center" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <h3>{text}</h3>
                            <a href={'/contact-us'} target={'_self'} className={'contact-button'}>Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ActionWidget;
