import {FC} from "react";
import './index.css'

interface IProps {
    data: {title: string, imgSrc: string, description: string, url: string};
}

export const ServiceCard: FC<IProps> = ({data}) => {
    return (
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12">
            <a href={data.url} className={'icon-card'}>
                <div className="icon-wrap">
                    <img src={data.imgSrc} alt={'Service'} className={'image'} height={200} />
                </div>
                <div className="content">
                    <h2 className='title'>{data.title}</h2>
                    <p className={'text'}>{data.description}</p>
                </div>
            </a>
        </div>
    )
}
